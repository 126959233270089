<template>
  <section class="page_content">
    <div class="container">
      <main-heading-block class="page_content_head animate__animated animate__slow animate__fadeInUp">
        <h2> {{ $t('page_content_head.title') }}</h2>
        <p> {{ $t('page_content_head.description') }} </p>
        <p> {{ $t('page_content_head.description2') }} </p>
      </main-heading-block>
    </div>
  </section>
</template>
<script>
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";

export default {
  components: {MainHeadingBlock}
};
</script>

<style lang="scss" scoped>
.page_content {
  background-color: var(--bg);
  width: 100%;
  background-image: url(../../assets/svg/bgcity.svg);
  background-position: bottom;
  background-repeat: no-repeat;
}

.page_content_head {
  min-height: 300px;
}

</style>
