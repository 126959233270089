<template>
  <div id="container">
    <div class="macbook">
      <div class="inner">
        <div class="screen">
          <div class="face-one">
            <div class="camera"></div>
            <div class="display">
              <iframe type="text/html"
                      src="https://www.youtube.com/embed/0lbzmZeS-BY?&autoplay=1&mute=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=0lbzmZeS-BY"
                      frameborder="0" allowfullscreen></iframe>
              <div class="shade"></div>
            </div>
            <span>MacBook Pro</span>
          </div>
          <img src="../../assets/Apple_logo.png" alt="" class="logo" draggable="false">
        </div>
        <div class="body">
          <div class="face-one">
            <div class="touchpad">
            </div>
            <div class="keyboard">
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key space"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
              <div class="key f"></div>
            </div>
          </div>
          <div class="pad one"></div>
          <div class="pad two"></div>
          <div class="pad three"></div>
          <div class="pad four"></div>
        </div>
      </div>
      <div class="shadow"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: '',
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>
<style lang="scss" scoped>

$color1: #aaa;
$color2: #dedede;
$color3: #a9a9a9;

#container {
  position: absolute;
  max-width: 40vmin;
  width: 40vmin;
  height: 40vmin;
  margin: 0 auto;
  padding: 3vmin;
}

.macbook {
  width: 20vmin;
  height: 12.6vmin;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -7.5vmin 0 0 -4.8vmin;
  perspective: 50vmin;
  user-select: none;
}

.shadow {
  position: absolute;
  width: 9vmin;
  height: 0;
  left: 4vmin;
  top: 19vmin;
  transform: rotateX(80deg) rotateY(0deg) rotateZ(0deg);
  box-shadow: 0 0 6vmin 4vmin rgba(0, 0, 0, 0.3);
  animation: shadow infinite 7s ease;
}

.inner {
  z-index: 20;
  position: absolute;
  width: 20vmin;
  height: 12.6vmin;
  left: 0;
  top: 0;
  transform-style: preserve-3d;
  transform: rotateX(-20deg) rotateY(0deg) rotateZ(0deg);
  animation: rotate infinite 7s ease;
}

.screen {
  width: 20vmin;
  height: 12.6vmin;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0.7vmin;
  background-color: #ddd;
  transform-style: preserve-3d;
  transform-origin: 50% 12.1vmin;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  animation: lid-screen infinite 7s ease;
  background-image: linear-gradient(256.38deg, $color1 -47.45%, $color2 146.11%);
  background-position: left bottom;
  background-size: 30vmin 30vmin;
  box-shadow: inset 0 0.3vmin 0.7vmin rgba(255, 255, 255, 0.5);
}

.screen .logo {
  position: absolute;
  width: 4vmin;
  height: 2.4vmin;
  left: 50%;
  top: 50%;
  margin: -1.2vmin 0 0 -1vmin;
  transform: rotateY(180deg) translateZ(0.01vmin);
}

.screen .face-one {
  width: 20vmin;
  height: 12.6vmin;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0.7vmin;
  background: #d3d3d3;
  transform: translateZ(.2vmin);
  background-image: linear-gradient(256.38deg, $color3 -47.45%, $color2 146.11%);;
}

.screen .face-one .camera {
  width: 0.3vmin;
  height: 0.3vmin;
  border-radius: 100%;
  background: #000;
  position: absolute;
  left: 50%;
  top: 0.4vmin;
  margin-left: -1.0 .5vmin;
}

.screen .face-one .display {
  width: 18vmin;
  height: 10.7vmin;
  margin: 1.1vmin;
  background: url("https://upload.wikimedia.org/wikipedia/en/9/98/MacOS_Monterey_Desktop.png") no-repeat center center #000;
  background-size: 100% 100%;
  border-radius: 0.1vmin;
  position: relative;
  box-shadow: inset 0 0 0.2vmin rgba(0, 0, 0, 1);
}

.screen .face-one .display .shade {
  position: absolute;
  left: 0;
  top: 0;
  width: 18vmin;
  height: 11.4vmin;
  background-image: linear-gradient(-135deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 47%, rgba(255, 255, 255, 0) 48%);
  animation: screen-shade infinite 7s ease;
  background-size: 30vmin 20vmin;
  background-position: 0 0;
}

.screen .face-one span {
  position: absolute;
  top: 80.5vmin;
  left: 5.7vmin;
  font-size: .6vmin;
  color: #666
}

.body {
  width: 20vmin;
  height: 12.6vmin;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0.7vmin;
  background-color: #cbcbcb;
  transform-style: preserve-3d;
  transform-origin: 50% bottom;
  transform: rotateX(-90deg);
  animation: lid-body infinite 7s ease;
  background-image: linear-gradient(256.38deg, $color1 -47.45%, $color2 146.11%);;
}

.body .face-one {
  width: 20vmin;
  height: 12.6vmin;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0.7vmin;
  transform-style: preserve-3d;
  background-color: #dfdfdf;
  animation: lid-keyboard-area infinite 7s ease;
  transform: translateZ(-0.2vmin);
  background-image: linear-gradient(256.38deg, $color1 -47.45%, $color2 146.11%);;
}

.body .touchpad {
  width: 4.4vmin;
  height: 3.1vmin;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 0.4vmin;
  margin: - 6vmin 0 0 -1.8vmin;
  background-color: #cdcdcd;
  background-image: linear-gradient(30deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0) 100%);
  box-shadow: inset 0 0 0.3vmin #888;
}

.body .keyboard {
  width: 17.6vmin;
  height: 6.9vmin;
  position: absolute;
  left: 0.7vmin;
  top: 4vmin;
  border-radius: .4vmin;
  transform-style: preserve-3d;
  background-color: #cdcdcd;
  background-image: linear-gradient(30deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0) 100%);
  box-shadow: inset 0 0 0.3vmin #777;
  padding: 0 0 0 0.2vmin;
}

.keyboard .key {
  width: 1vmin;
  height: 1vmin;
  background: #444;
  float: left;
  margin: 0.12vmin;
  transform: translateZ(-0.2vmin);
  border-radius: 0.2vmin;
  box-shadow: 0 -0.2vmin 0 #222;
  animation: keys infinite 7s ease;
}

.key.space {
  width: 4.8vmin;
}

.key.f {
  height: 0.3vmin;
}

.body .pad {
  width: 0.5vmin;
  height: 0.5vmin;
  background: #333;
  border-radius: 100%;
  position: absolute;
}

.pad.one {
  left: 2vmin;
  top: 2vmin;
}

.pad.two {
  right: 2vmin;
  top: 2vmin;
}

.pad.three {
  right: 2vmin;
  bottom: 2vmin;
}

.pad.four {
  left: 2vmin;
  bottom: 2vmin;
}

.display iframe {
  width: 17vmin;
  height: 9.6vmin;
}

@keyframes rotate {
  0% {
    transform: rotateX(-20deg) rotateY(0deg) rotateZ(0deg);
  }
  5% {
    transform: rotateX(-20deg) rotateY(-20deg) rotateZ(0deg);
  }
  20% {
    transform: rotateX(30deg) rotateY(200deg) rotateZ(0deg);
  }
  25% {
    transform: rotateX(-60deg) rotateY(150deg) rotateZ(0deg);
  }
  60% {
    transform: rotateX(-20deg) rotateY(130deg) rotateZ(0deg);
  }
  65% {
    transform: rotateX(-20deg) rotateY(120deg) rotateZ(0deg);
  }
  80% {
    transform: rotateX(-20deg) rotateY(375deg) rotateZ(0deg);
  }
  85% {
    transform: rotateX(-20deg) rotateY(357deg) rotateZ(0deg);
  }
  87% {
    transform: rotateX(-20deg) rotateY(360deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(-20deg) rotateY(360deg) rotateZ(0deg);
  }
}

@keyframes lid-screen {
  0% {
    transform: rotateX(0deg);
    background-position: left bottom;
  }
  5% {
    transform: rotateX(50deg);
    background-position: left bottom;
  }
  20% {
    transform: rotateX(-90deg);
  }
  25% {
    transform: rotateX(15deg);
    background-position: left bottom;
  }
  30% {
    transform: rotateX(-5deg);
    background-position: right top;
  }
  38% {
    transform: rotateX(5deg);
    background-position: right top;
  }
  48% {
    transform: rotateX(0deg);
    background-position: right top;
  }
  90% {
    transform: rotateX(0deg);
    background-position: right top;
  }
  100% {
    transform: rotateX(0deg);
    background-position: right center;
  }
}

@keyframes lid-body {
  0% {
    transform: rotateX(-90deg);

  }
  50% {
    transform: rotateX(-90deg);

  }
  100% {
    transform: rotateX(-90deg);

  }
}

@keyframes lid-keyboard-area {
  0% {
    background-color: #dfdfdf;
  }
  50% {
    background-color: #bbb;
  }
  100% {
    background-color: #dfdfdf;
  }
}

@keyframes screen-shade {
  0% {
    background-position: -2vmin 0;
  }
  5% {
    background-position: -4vmin 0;
  }
  20% {
    background-position: 20vmin 0;
  }
  50% {
    background-position: -20vmin 0;
  }
  80% {
    background-position: 0 0;
  }
  85% {
    background-position: -3vmin 0;
  }
  90% {
    background-position: -2vmin 0;
  }
  100% {
    background-position: -2vmin 0;
  }
}

@keyframes keys {
  0% {
    box-shadow: 0 -0.2vmin 0 #222;
  }
  5% {
    box-shadow: 1 -0.1vmin 0 #222;
  }
  20% {
    box-shadow: -0.1vmin 0.1vmin 0 #222;
  }
  25% {
    box-shadow: -0.1vmin 0.1vmin 0 #222;
  }
  60% {
    box-shadow: -0.1vmin 0.1vmin 0 #222;
  }
  80% {
    box-shadow: 0 -0.2vmin 0 #222;
  }
  85% {
    box-shadow: 0 -0.2vmin 0 #222;
  }
  87% {
    box-shadow: 0 -0.2vmin 0 #222;
  }
  100% {
    box-shadow: 0 -0.2vmin 0 #222;
  }
}

@keyframes shadow {
  0% {
    transform: rotateX(80deg) rotateY(0deg) rotateZ(0deg);
    box-shadow: 0 0 6vmin 4vmin rgba(0, 0, 0, 0.3);
  }
  5% {
    transform: rotateX(80deg) rotateY(10deg) rotateZ(0deg);
    box-shadow: 0 0 6vmin 4vmin rgba(0, 0, 0, 0.3);
  }
  20% {
    transform: rotateX(30deg) rotateY(-20deg) rotateZ(-20deg);
    box-shadow: 0 0 5vmin 3vmin rgba(0, 0, 0, 0.3);
  }
  25% {
    transform: rotateX(80deg) rotateY(-20deg) rotateZ(50deg);
    box-shadow: 0 0 3.5vmin 1.5vmin rgba(0, 0, 0, 0.1);
  }
  60% {
    transform: rotateX(80deg) rotateY(0deg) rotateZ(-50deg) translateX(3vmin);
    box-shadow: 0 0 6vmin 4vmin rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 6vmin 4vmin rgba(0, 0, 0, 0.3);
  }
}

.links {
  position: absolute;
  right: 2vmin;
  bottom: 2vmin;
}

.links a {
  color: #555;
  margin-left: 1vmin;
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .macbook {
    transform: scale(1.4) translateX(-13%);
  }
}

@media (max-width: 767.98px) {
  .macbook {
   transform: scale(1.9) translateX(-13%);
  }
}

</style>

