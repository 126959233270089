<template>
  <section class="methods_content">
    <div class="container">
      <div class="wrapper">
        <main-heading-block>
          <h2 class="heading animate__animated"> {{ $t('methods_content_head.title') }} </h2>
          <p class="description animate__animated "> {{ $t('methods_content_head.description') }} </p>
        </main-heading-block>
        <div class="methods_content_blocs">
          <div class="methods_content_bloc">
            <content-block-item
                v-for="(blocItem, key) in this.$i18n.messages[this.$i18n.locale].methods_content_bloc_items" :key="key"
                :bloc-item="blocItem"
                class="animate__animated methods_content_bloc_item"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";
import ContentBlockItem from "@/components/blocks/ContentBlockItem";
import HandleScrollMixin from "@/mixins/HandleScrollMixin";

export default {
  components: {ContentBlockItem, MainHeadingBlock},
  mixins: [HandleScrollMixin],
  methods: {
    onScroll: function () {
      this.handleScroll('.methods_content_bloc_item', 'animate__flipInX', 150, false)
      this.handleScroll('.heading', 'animate__flipInX', 200, false)
      this.handleScroll('.description', 'animate__fadeIn', 200, false)
    },
  },
  created() {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>

.methods_content_bloc_item,
.heading,
.description {
  opacity: 0;
}

.methods_content_bloc_item.animate__flipInX,
.heading.animate__flipInX,
.description.animate__fadeIn {
  opacity: 1;
}

.methods_content {
  background-color: var(--bg);
  width: 100%;
  margin: 0 auto;
}

.methods_content_blocs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3vmin;
}

.methods_content_bloc {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.methods_content_bloc > div {
  margin-top: 6vmin;
  flex-grow: 1;
  flex-basis: 30%;
}

.wrapper {
  margin: 2vmin auto;
}
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .methods_content_bloc > div {
    margin-top: 3rem;
    flex-basis: 50%;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .methods_content_blocs {
    margin-top: 3rem;
  }

  .methods_content_bloc > div {
    margin-top: 4rem;
    flex-basis: 80%;
    max-width: 90%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .methods_content_blocs {
    background-size: 100% auto;
  }

  .methods_content_bloc > div {
    flex-basis: 100%;
    max-width: unset
  }
}
</style>
